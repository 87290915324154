(function () {
	const inputs = document.getElementsByClassName('form-field');

	for (const inputWrap of inputs) {
		const input = inputWrap.getElementsByClassName('form-field__input')[0];
		const fieldWrap = inputWrap.getElementsByClassName('form-field__wrap')[0];

		const toggleFilledModifier = (node) => {
			if (node.value) node.classList.add('--filled');
			else node.classList.remove('--filled');
		};

		// Placeholder location onload
		toggleFilledModifier(input);

		// Placeholder location onchange input value
		input.addEventListener('change', () => toggleFilledModifier(input));

		// Placeholder location onfocus input
		input.addEventListener('focus', () => toggleFilledModifier(input));

		// Creating placeholder
		const inputPlaceholder = document.createElement('div');
		inputPlaceholder.className = 'form-field__placeholder';
		inputPlaceholder.innerHTML = input.placeholder;
		fieldWrap.appendChild(inputPlaceholder);

		// Focus on input when placeholder is clicked
		inputPlaceholder.addEventListener('click', () => input.focus());
	}

})();