(function () {
    const validateMainRound = () => {
        let flight_from = document.forms['round-form']['flight_from'].value;
        let flight_to = document.forms['round-form']['flight_to'].value;
        let departure_date = document.forms['round-form']['departure_date'].value;
        let return_date = document.forms['round-form']['return_date'].value;
        let counter = 0;
        if (!flight_from) {
            $('#oneRoadFrom').addClass('warning');
            counter += 1;
        }
        if (!flight_to) {
            $('#oneRoadTo').addClass('warning');
            counter += 1;
        }
        if (!departure_date) {
            $('#date-oneRoadFrom').addClass('warning');
            counter += 1;
        }
        if (!return_date) {
            $('#date-oneRoadTo').addClass('warning');
            counter += 1;
        }
        if (counter === 0) return true;
        return false;
    };

    const form = document.getElementById('round-form');
    if (form) form.onsubmit = validateMainRound;
})();
(function () {
    const validateMainOne = () => {
        let flight_from = document.forms['one-way']['flight_from'].value;
        let flight_to = document.forms['one-way']['flight_to'].value;
        let departure_date = document.forms['one-way']['departure_date'].value;
        let counter = 0;
        if (!flight_from) {
            $('#oneRoadFrom2').addClass('warning');
            counter += 1;
        }
        if (!flight_to) {
            $('#oneRoadTo2').addClass('warning');
            counter += 1;
        }
        if (!departure_date) {
            $('#date-oneRoadFrom2').addClass('warning');
            counter += 1;
        }
        if (counter === 0) return true;
        return false;
    };
    const form = document.getElementById('one-way-form');
    if (form) form.onsubmit = validateMainOne;
})();

(function () {
    const validateMainMulti = () => {

        let inputFrom0 = 'default';
        let inputFrom1 = 'default';
        let inputFrom2 = 'default';
        let inputFrom3 = 'default';
        let inputTo0 = 'default';
        let inputTo1 = 'default';
        let inputTo2 = 'default';
        let inputTo3 = 'default';
        let date_from0 = 'default';
        let date_from1 = 'default';
        let date_from2 = 'default';
        let date_from3 = 'default';
        let counter = 0;

        if ($('#row0').css('display') !== 'none') {
            inputFrom0 = document.forms['multi-city']['flights[0][flight_from]'].value;
            inputTo0 = document.forms['multi-city']['flights[0][flight_to]'].value;
            date_from0 = document.forms['multi-city']['flights[0][departure_date]'].value;
        }
        if ($('#row1').css('display') !== 'none') {
            inputFrom1 = document.forms['multi-city']['flights[1][flight_from]'].value;
            inputTo1 = document.forms['multi-city']['flights[1][flight_to]'].value;
            date_from1 = document.forms['multi-city']['flights[1][departure_date]'].value;
        }
        if ($('#row2').css('display') !== 'none') {
            inputFrom2 = document.forms['multi-city']['flights[2][flight_from]'].value;
            inputTo2 = document.forms['multi-city']['flights[2][flight_to]'].value;
            date_from2 = document.forms['multi-city']['flights[2][departure_date]'].value;
        }
        // if ($('#row3').css('display') !== 'none') {
        //     inputFrom3 = document.forms['multi-city']['flights[3][flight_from]'].value;
        //     inputTo3 = document.forms['multi-city']['flights[3][flight_to]'].value;
        //     date_from3 = document.forms['multi-city']['flights[3][departure_date]'].value;
        // }
        let name = document.forms['multi-city']['name'].value;
        let phone = document.forms['multi-city']['phone'].value;
        let email = document.forms['multi-city']['email'].value;
        if (!inputFrom0) {
            $('#inputFrom0').addClass('warning');
            counter += 1;
        }
        if (!inputFrom1) {
            $('#inputFrom1').addClass('warning');
            counter += 1;
        }
        if (!inputFrom2) {
            $('#inputFrom2').addClass('warning');
            counter += 1;
        }
        // if (!inputFrom3) {
        //     $('#inputFrom3').addClass('warning');
        //     counter += 1;
        // }
        if (!inputTo0) {
            $('#inputTo0').addClass('warning');
            counter += 1;
        }
        if (!inputTo1) {
            $('#inputTo1').addClass('warning');
            counter += 1;
        }
        if (!inputTo2) {
            $('#inputTo2').addClass('warning');
            counter += 1;
        }
        // if (!inputTo3) {
        //     $('#inputTo3').addClass('warning');
        //     counter += 1;
        // }
        if (!date_from0) {
            $('#date-from0').addClass('warning');
            counter += 1;
        }
        if (!date_from1) {
            $('#date-from1').addClass('warning');
            counter += 1;
        }
        if (!date_from2) {
            $('#date-from2').addClass('warning');
            counter += 1;
        }
        // if (!date_from3) {
        //     $('#date-from3').addClass('warning');
        //     counter += 1;
        // }
        if (!name) {
            $('#multi_name').addClass('warning');
            counter += 1;
        }
        if (!phone) {
            $('#multi_phone').addClass('warning');
            counter += 1;
        }
        if (!email) {
            $('#multi_email').addClass('warning');
            counter += 1;
        }
        if (counter === 0) return true;
        return false;
    };
    const form = document.getElementById('multi-city-form');
    if (form) form.onsubmit = validateMainMulti;
})();

(function () {
    const validateSecondStep = () => {
        let name  = document.forms['second-step-form']['name'].value;
        // let email = document.forms['second-step-form']['email'].value;
        // let phone = document.forms['second-step-form']['full_phone'].value;

        let counter = 0;

        if (!name) {
            console.warn(123);
            // $('#oneRoadFrom').addClass('warning');
            counter += 1;
        }
        // if (!email) {
        //     $('#oneRoadTo').addClass('warning');
        //     counter += 1;
        // }
        // if (!phone) {
        //     $('#date-oneRoadFrom').addClass('warning');
        //     counter += 1;
        // }
        // if (counter === 0) return true;
        // return false;
    };

    const form = $('#second-step-form');
    if (form) form.onsubmit = validateSecondStep;
})();
