(function () {
	const counters = document.getElementsByClassName('counter-component');
	for (const counter of counters) {
		const plusBtn = counter.getElementsByClassName('counter-component__btn-plus')[0];
		const minusBtn = counter.getElementsByClassName('counter-component__btn-minus')[0];
		const input = counter.getElementsByClassName('counter-component__input')[0];
		const value = counter.getElementsByClassName('counter-component__value')[0];

		// input.value;
		// value.innerHTML;

		plusBtn.addEventListener('click', () => {
			const inputValue = parseInt(input.value, 10);
			input.value = inputValue + 1;
			value.innerHTML = inputValue + 1;
		});

		minusBtn.addEventListener('click', () => {
			const inputValue = parseInt(input.value, 10);
			if (inputValue > 1) {
				input.value = inputValue - 1;
				value.innerHTML = inputValue - 1;
			}
		})
	}
})();
