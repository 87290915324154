class Tabs {
	constructor(tabsWrapper) {
		const tabButtons = tabsWrapper.getElementsByClassName('tabs-component_btn');
		const tabContents = tabsWrapper.getElementsByClassName('tabs-component_content__item');
		const activeModifier = '--active';

		if (tabButtons.length === tabContents.length) {
			// Show first tab on load
			// tabButtons[0].classList.add(activeModifier);
			// tabContents[0].classList.add(activeModifier);

			for (let i = 0; i < tabButtons.length; i += 1) {
				tabButtons[i].addEventListener('click', () => {
					// Remove all active modifier from all tab buttons
					for (const content of tabContents) {
						content.classList.remove(activeModifier);
					}
					// Remove all active modifier from all tab contents
					for (const btn of tabButtons) {
						btn.classList.remove(activeModifier);
					}

					// Set active clicked tab
					tabContents[i].classList.add(activeModifier);
					tabButtons[i].classList.add(activeModifier);
				})
			}
		} else console.error('For each tab must be one content container!')
	}
}

export default Tabs;
