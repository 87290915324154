$(document).ready(() => {
    // $('.select-class').select2({
    //     width: '104.7%'
    // });
    const prevImage = "/main/WXUx0tM.png";
    const nextImage = "/main/FOIN0lM.png";

    $('.flights-slider').owlCarousel({
        loop: true,
        center: true,
        lazyLoad:true,
        nav: true,
        margin: 60,
        autoWidth: true,
        navText: [`<img class='previous_carousel' src='${prevImage}' alt='prev' />`,  `<img class='next_carousel' src='${nextImage}' alt='next'/>`],
        responsive: {
            320: {
                items: 1,
            },
            380: {
                items: 2,
            },
            576: {
                items: 3,

            },
            968: {
                items: 4,
            }
        }
    });

    // Sticky nav
    const windowLocation = window.location.pathname;
    const searchLocation = '/flight/search/';
    const notSearchPage = windowLocation.indexOf(searchLocation) === -1;

    $(window).scroll(function() {
        if ($(window).scrollTop() > 180 && notSearchPage) {
            $('.navbar').addClass('fixed_navbar');
            $('.non-fixed').css('display', 'none');
            $('.fixed').css('display', 'flex');
            $('#open-menu').css('display', 'none');
            $('#open-menu-scroll').css('display', 'flex');
            $('.menu').css('position', 'fixed');
            $('.overlay').css('position', 'fixed');

        } else {
            $('.navbar').removeClass('fixed_navbar');
            $('.non-fixed').css('display', 'flex');
            $('.fixed').css('display', 'none');
            $('#open-menu').css('display', 'flex');
            $('#open-menu-scroll').css('display', 'none');
            $('.menu').css('position', 'absolute');
            $('.overlay').css('position', 'absolute');
        }
    });
    // End sticky nav


    $('#one-way').click(() => {
        let width = window.innerWidth;
        if(width > 576) {
            $('.date-picker-component__body').css({
                'transform': 'translateX(100%)translateY(5px)'
            });
        }
        $('.landing__h1').animate({
            'opacity': '1'
        }, 500).slideDown('slow');
    });
    $('#round').click(() => {
        let width = window.innerWidth;
        if(width > 576) {
            $('.date-picker-component__body').css({
                'transform': 'translateX(20%)translateY(5px)'
            });
        }
        $('.landing__h1').animate({
            'opacity': '1'
        }, 500).slideDown('slow');
    });
    $('#multi').click(function() {
        let width = window.innerWidth;
        if(width > 576) {
            $('.date-picker-component__body').css({
                'transform': 'translateX(0)translateY(5px)'
            });
        }
        $('.landing__h1').animate({
            'opacity': '0'
        }, 500).slideUp('slow');
    });


    // $("#multi_phone").on("countrychange", function () {
    //     $('#multi_phone').val('');
    // });

    $('#multi_phone, #contact_phone, #booking_phone').on('keyup', function () {
        //let val = $(this).val().replace(/[^0-9]*/gm, '');
        //const flagSelect = $('#multi-city-form').find('.iti__selected-flag')[0].getAttribute('title');
        const flagSelect = $(this).parent().find('.iti__selected-flag')[0].getAttribute('title');
        let val;

        if (flagSelect === 'United States: +1') {
            $(this).attr('maxlength', '12');
            val = $(this).val().replace(/[^0-9]*/gm, '').replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/, function(_, p1, p2, p3, p4){
                let output = ""
                if (p1) output = `${p1}`;
                if (p2) output += `${p2}`;
                if (p3) output += `-${p3}`;
                if (p4) output += `-${p4}`;
                return output;
            });
        } else {
            $(this).attr('maxlength', '17');
            val = $(this).val().replace(/[^0-9]*/gm, '').replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/, function(_, p1, p2, p3, p4){
                let output = ""
                if (p1) output = `${p1}`;
                if (p2) output += `${p2}`;
                if (p3) output += ` ${p3}`;
                if (p4) output += ` ${p4}`;
                return output;
            });
        }
        $(this).val(val);
    });

    if(location.pathname === '/' || location.pathname === '/first-class' || location.pathname.startsWith('/popular-destinations')) {
        let multi_phone = document.getElementById("multi_phone");
        window.intlTelInput(multi_phone, {
            separateDialCode: true,
            formatOnDisplay: true,
            hiddenInput: "full_phone",
            // utilsScript: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/32471/utils.js",
            utilsScript: '/intl-tel-input/build/js/utils.js'
            // nationalMode:true,
        });
        multiPhone = $('#multi_phone');
        multiPhone.focus(() => {
            let telSelectWidth = $('.iti__flag-container').innerWidth();

            $('.multi_phone_placeholder .form-field__placeholder').css('opacity', '0');
            $('#multi_phone').css('padding', `17px 16px 16px ${parseInt(telSelectWidth, 10) + 5}px`);
        });

        let contact_phone = document.getElementById("contact_phone");
        window.intlTelInput(contact_phone, {
            separateDialCode: true,
            formatOnDisplay: true,
            hiddenInput: "full_phone",
            // utilsScript: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/32471/utils.js",
            utilsScript: '/intl-tel-input/build/js/utils.js'
        });
        contactPhone = $('#contact_phone');
        contactPhone.focus(() => {
            let telSelectWidth = $('.iti__flag-container').last().innerWidth();
            $('.contact_phone_placeholder .form-field__placeholder').css('opacity', '0');
            $('#contact_phone').css('padding', `17px 16px 16px ${parseInt(telSelectWidth, 10) + 5}px`);
        });

    } else {

        let booking_phone = document.getElementById("booking_phone");
        window.intlTelInput(booking_phone, {
            separateDialCode: true,
            formatOnDisplay: true,
            hiddenInput: "full_phone",
            // utilsScript: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/32471/utils.js",
            utilsScript: '/intl-tel-input/build/js/utils.js'
        });
        bookingPhone = $('#booking_phone');
        bookingPhone.focus(() => {
            let telSelectWidth = $('.iti__flag-container').innerWidth();
            $('.booking_phone_placeholder .form-field__placeholder').css('opacity', '0');
            $('#booking_phone').css('padding', '17px 16px 16px 92px');
            // $('#booking_phone').css('padding', `17px 16px 16px 78px, ${parseInt(telSelectWidth, 10) + 5}px`);
        });

        let contact_phone = document.getElementById("contact_phone");
        window.intlTelInput(contact_phone, {
            separateDialCode: true,
            formatOnDisplay: true,
            hiddenInput: "full_phone",
            // utilsScript: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/32471/utils.js",
            utilsScript: '/intl-tel-input/build/js/utils.js'
        });
        contactPhone = $('#contact_phone');
        contactPhone.focus(() => {
            let telSelectWidth = $('.iti__flag-container').last().innerWidth();
            $('.contact_phone_placeholder .form-field__placeholder').css('opacity', '0');
            $('#contact_phone').css('padding', `17px 16px 16px ${parseInt(telSelectWidth, 10) + 5}px`);
        });
    }

});

$('.form-field__input ').click(function(e){
    let width = window.innerWidth;
    let elementId = $(this).attr('id');
    if (elementId !== undefined && (elementId.indexOf('Road') != -1 || elementId.indexOf('input') != -1 || elementId.indexOf('from') !== -1)) {
        let elClass = $(`#${elementId}`).parents('.form-field');
        if(width < 576) {
            $('.input_overlay').css('display', 'flex');
            $('.navbar').css('z-index', '-1');
            $('.middle-block-setter').addClass('mobileView');
            elClass.addClass('mobileView');
            $('.destination__list').addClass('mobileView');
            if(elementId.indexOf('date') != -1) {
                $('.date-picker-component, .date-picker-component__body, .pika-single, .pika-lendar').addClass('staticCalendar');
            }
            if(elementId.indexOf('date') == -1){
                $('.date-picker-component').removeClass('staticCalendar');
            }
            setTimeout(function () {
                $(window).scrollTop(0);
            }, 200);

            $('html').css('overflow', 'hidden');
            $('.js-cookie-consent.cookie-consent').css('z-index', '0');
        }

        $('.close_mobileView, .destination__list').click(function(){
            $('.input_overlay').css('display', 'none');
            $('.navbar').css('z-index', '995');
            $('.middle-block-setter').removeClass('mobileView');
            elClass.removeClass('mobileView');
            $('.destination__list').removeClass('mobileView');
            $('.date-picker-component, .date-picker-component__body, .pika-single, .pika-lendar').removeClass('staticCalendar');
            $('.date-picker-component').css('display', 'none');
            $('html').css('overflow', 'auto');
            $('.js-cookie-consent.cookie-consent').css('z-index', '99999');
        });

    }
});
$(window).resize(function() {
    let width = window.innerWidth;
    if (width < 768) {
        // {{--$('#header-section').attr("style", "background-image: url('{{asset('main/topBg2.jpg')}}')");--}}
        $('#about-us-section').attr("style", "background-image: url('{{asset('main/about-us.jpg')}}')");
        // $('#header-section_fc').attr("style", "background-image: url('{{asset('main/2.jpg')}}')");

    } else {
        $('#about-us-section').attr("style", "background-image: url('{{asset('main/about-us2.jpg')}}')");
        // $('#header-section_fc').attr("style", "background-image: url('{{asset('main/1.jpg')}}')");
    }
});



$('#open-menu').click(() => {
    $('.menu').toggle('fast');
    $('.overlay').css('display', 'flex');
});
$('#open-menu-scroll').click(() => {
    $('.menu').toggle('fast');
    $('.overlay').css('display', 'flex');
});
$('.close-menu').click(() => {
    $('.menu').toggle('fast');
    $('.overlay').css('display', 'none');
});

let value = 0;
$('#plus').click((e) => {
    value += 1;
    $('#counter').val(value);
    e.preventDefault();
});
$('#minus').click((e) => {
    if (value > 0) {
        value += -1;
        $('#counter').val(value);
        e.preventDefault();
    } else {
        return ($('#counter').val(0), e.preventDefault());
    }
});

$("#about-us").click(() => {
    $('.menu').toggle('fast');
    $('.overlay').css('display', 'none');
});
$("#offers").click(() => {
    $('.menu').toggle('fast');
    $('.overlay').css('display', 'none');
});
$("#popular-destinations").click(() => {
    $('.menu').toggle('fast');
    $('.overlay').css('display', 'none');
});
$("#howToBook").click(() => {
    $('.menu').toggle('fast');
    $('.overlay').css('display', 'none');
});
$("#reviews_link").click(() => {
    $('.menu').toggle('fast');
    $('.overlay').css('display', 'none');
});
$("#contact_us").click(() => {
    $('.menu').toggle('fast');
    $('.overlay').css('display', 'none');
});
$('.overlay').click(() => {
    $('.menu').toggle('fast');
    $('.overlay').css('display', 'none');
});


$(document).on('click', 'a[href^="#"]', function (event) {
    $('.menu ul li a').removeClass('active-menu-link');
    $(this).addClass('active-menu-link');
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - 60
    }, 1000);
});
$('#date-oneRoadTo').on('change', function () {
    $('html').css('overflow', 'auto');
});
$('#date-oneRoadFrom2').on('change', function () {
    $('html').css('overflow', 'auto');
});
$('#date-from0').on('change', function () {
    $('html').css('overflow', 'auto');
});
$('#date-from1').on('change', function () {
    $('html').css('overflow', 'auto');
});
$('#date-from2').on('change', function () {
    $('html').css('overflow', 'auto');
});
function canUseWebP() {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
}
if (!canUseWebP()) {
    document.body.className = "no-webp";
}
