import Pikaday from 'pikaday';
import moment from 'moment';

(function () {
	const datePickers = document.getElementsByClassName('datepicker-component');

	for (const datepicker of datePickers) {
		new Pikaday({
			field: datepicker,
			format: 'D MMMM YYYY',
			minDate: moment(),
			disableDayFn: (e) => moment(e).isBefore(moment().subtract(1, 'day')),
			theme: 'date-picker-component__body',
			onSelect: function () {
				let width = window.innerWidth;
				if(width < 576){
					$('.date-picker-component, .date-picker-component__body, .pika-single, .pika-lendar').removeClass('staticCalendar');
					let elClass = $('.form-field');
					$('.input_overlay').css('display', 'none');
					$('.navbar').css('z-index', '995');
					elClass.removeClass('mobileView');
					$('.destination__list').removeClass('mobileView');
				}
			}
		});
	}

})();